const printButton = document.querySelector('.js-print');
const hamburger = document.querySelector('.js-hamburger');
const dropdown = document.querySelector('.dropdown');
const innerMenu = document.querySelector('.header__navbar-menu');

function printPage () {
    return window.print();
};

function openMenu () {
    this.classList.toggle('is-open');
    dropdown.classList.toggle('is-open');
    innerMenu.classList.toggle('is-open');
};

printButton != null ? printButton.addEventListener('click', printPage) : 'empty';
hamburger.addEventListener('click', openMenu);
